import Link from 'next/link';

const WishlistEventNotification: React.FC<any> = (props) => {
  const { product, wishlist, eventType } = props;

  function getLinkMaxWidthStyle(): string {
    switch (eventType) {
      case 'addToWishlist':
        return '223px';
      case 'removeFromWishlist':
        return '175px';
      case 'createWishlist':
      case 'updateWishlist':
      case 'removeWishlist':
        return '225px';
      default: return '100%';
    }
  }

  const linkStyles = {
    textOverflow: 'ellipsis',
    maxWidth: getLinkMaxWidthStyle(),
  }
  return(
    <div>
      <img
        className="w-[106px] mr-[26px] inline-block"
        src={
          product?.poster ||
          product?.image ||
          product?.media?.image ||
          wishlist.products && wishlist.products[0]?.image ||
          '/img/placeholder-wishlist.png'
        }
      />
      <div className="inline-block align-middle" style={{width: 'calc(100% - 132px)'}}>
        {eventType === 'addToWishlist' && 'Saved to'}
        {eventType === 'removeFromWishlist' && 'Removed from'}
        {eventType === 'createWishlist' && 'Created'}
        {eventType === 'updateWishlist' && 'Updated'}
        {eventType === 'removeWishlist' && (<>Deleted <strong>{wishlist.name}</strong></>)}
        {eventType !== 'removeWishlist' && (
          <Link className="text-a-blue overflow-hidden whitespace-nowrap inline-block align-bottom ml-[5px]" href={`/accounts/wishlists/${wishlist.id}`} style={linkStyles}>
            { ` ${wishlist.name}` }
          </Link>
        )}
      </div>
    </div>
  )
}

export default WishlistEventNotification;
