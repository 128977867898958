import { Dialog } from '@headlessui/react';
import { ExitIcon } from '../../modules/Icons';

const YesNoPromptDialog: React.FC<any> = (props) => {
  const {
    promptText,
    dialogOpen,
    onResult = (result: boolean) => null,
    onDialogClosed = () => null
  } = props;
  return (
    <Dialog open={dialogOpen} onClose={() => onDialogClosed()} className="relative z-[99]">
      <div
        className="fixed inset-0 overflow-y-auto flex items-center justify-center bg-black/60"
        cy-test-id="yes-no-dialog">
        <Dialog.Panel>
          <div className="unlock-modal bg-gray-900 shadow-lg drop-shadow-lg rounded-[3px] p-6 text-white">
            <div className="text-[21px] leading-[28px] font-medium">
              Confirm
              <ExitIcon
                cy-test-id="exit-icon"
                className="float-right cursor-pointer h-[24px]"
                onClick={() => onResult(false)}
              />
            </div>
            <div className="block text-16 leading-24 mt-4 max-w-[400px]">
              {promptText || 'Are you sure?'}
            </div>
            <div className="block text-right mt-8">
              <button
                className="bg-a-blue hover:bg-[#24C1FF] text-white text-[14px] rounded-[5px] py-2 px-3"
                onClick={() => onResult(false)}
                cy-test-id="no-button">
                No
              </button>
              <button
                className="bg-white bg-opacity-[5%] hover:bg-[#EC5555] hover:bg-opacity-[25%] text-[#7F898D] hover:text-white text-[14px] rounded-[5px] py-2 px-3 ml-2.5"
                onClick={() => onResult(true)}
                cy-test-id="yes-button">
                Yes
              </button>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default YesNoPromptDialog;
