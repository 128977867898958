import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setWishlists } from '../state/actions/wishlistsActions';

import { ApplicationState } from '../types/state/storeTypes';
import { Wishlist } from '../types/state/reducers/wishlistsReducersTypes';

// shouldLoadNow paramter added in case we need to delay the loading until a condition is met
// defaults to true in other cases
export default function getWishlists(shouldLoadNow: boolean = true) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const state: ApplicationState = useSelector((state) => state) as ApplicationState;

  useEffect(() => {
    if (!shouldLoadNow || !state?.auth?.user?.token || !!state?.wishlists?.wishlists || loading) return;

    const fetchWishlists = async () => {
      setLoading(true);
      dispatch({
        type: '',
        api: {
          method: 'GET',
          url: `/wishlists`,
          headers: { authorization: state.auth.user.token },
          onError: (res: any) => {
            setLoading(false);
            setError(res);
            return false;
          },
          onSuccess: (res: { data: { wishlists: Array<Wishlist> } }) => {
            dispatch(setWishlists(res?.data?.wishlists));
            setLoading(false);
          }
        }
      });
    };

    fetchWishlists();
  }, [shouldLoadNow, state?.auth?.user?.token]);

  return { loading, data: state?.wishlists?.wishlists, error };
}
