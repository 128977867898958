import { WISHLISTS_ACTIONS } from './../../constants/actions';
import {
  Wishlist,
  WishlistProduct,
} from '../../types/state/reducers/wishlistsReducersTypes';

export const setWishlists = (wishlists: Array<Wishlist>) => ({
  type: WISHLISTS_ACTIONS.SET_WISHLISTS,
  wishlists: wishlists,
});

export const addWishlist = (wishlist: Wishlist) => ({
  type: WISHLISTS_ACTIONS.ADD_WISHLIST,
  wishlist: wishlist,
});

export const removeWishlist = (wishlist: Wishlist) => ({
  type: WISHLISTS_ACTIONS.REMOVE_WISHLIST,
  wishlist: wishlist,
});

export const updateWishlist = (wishlist: Wishlist) => ({
  type: WISHLISTS_ACTIONS.UPDATE_WISHLIST,
  wishlist: wishlist,
});

export const addToWishlist = (wishlist: Wishlist, product: WishlistProduct) => ({
  type: WISHLISTS_ACTIONS.ADD_TO_WISHLIST,
  wishlist: wishlist,
  product: product,
});

export const removeFromWishlist = (wishlist: Wishlist, product: WishlistProduct) => ({
  type: WISHLISTS_ACTIONS.REMOVE_FROM_WISHLIST,
  wishlist: wishlist,
  product: product,
});
