import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Menu } from '@headlessui/react';

// Wrapper component that handles varios dropdown-menu specific cases
// such as menu position change, resize etc
export default function MenuItemsCustomWrapper(props) {
  const {
    // content that would usually be rendered in the <Menu.Items> </Menu.Items> wrapper
    children,
    // open/close callback
    onDropdownToggle = (value: boolean) => null,
    // additional classes that would usally be assigned to the <Menu.Items> element
    dropdownClassName,
    // array of props that affect dropdown size, usually the items list
    propsToWatchForResize = []
  } = props;

  const [dropdownStyle, setDropdownStyle] = useState({});
  const [dropdownNode, setDropdownNode] = useState(null);
  const [dropdownPositionCalculated, setDropdownPositionCalculated] = useState(false);
  const windowWidth = useRef(window.innerWidth);

  function adjustDropdownPosition(windowWidth) {
    setDropdownPositionCalculated(false);
    setDropdownStyle({});
    if (!dropdownNode) return;
    setTimeout(() => {
      const newStyle: any = {};
      const windowHeight = window.innerHeight;

      const boundingClientRect = dropdownNode.getBoundingClientRect();

      const dropdownHeight = boundingClientRect.height;
      const dropdownPositionY = boundingClientRect.y;
      const menuShouldBeAboveButton = dropdownHeight + dropdownPositionY > windowHeight;
      if (menuShouldBeAboveButton) newStyle.marginTop = `-${(dropdownHeight + 30).toString()}px`;

      const dropdownWidth = boundingClientRect.width;
      const dropdownPositionX = boundingClientRect.x;
      const menuShouldAlignRight = dropdownWidth + dropdownPositionX > windowWidth.current + 100;
      if (menuShouldAlignRight)
        newStyle.marginLeft = windowWidth.current <= 425 ? `-190px` : `-280px`;

      setDropdownStyle(newStyle);
      setDropdownPositionCalculated(true);
    }, 1);
  }

  const menuDropdownRef = useCallback((node: any) => {
    setDropdownNode(node);
    const dropdownIsOpen: boolean = !!node;
    onDropdownToggle(dropdownIsOpen);
  }, []);

  useEffect(() => adjustDropdownPosition(windowWidth), [dropdownNode, ...propsToWatchForResize]);

  return (
    <Menu.Items
      as="div"
      className={`absolute bg-gray-900 max-h-[350px] w-[210px] md:w-[300px] overflow-scroll noscrollbar text-[14px] z-50 text-left text-white ${
        dropdownClassName || ''
      } ${dropdownPositionCalculated ? '' : 'opacity-0'}`}
      style={dropdownStyle}
      ref={menuDropdownRef}>
      {children}
    </Menu.Items>
  );
}
